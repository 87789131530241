import "./App.css";
import CTA from "./sections/CTA";
import Features from "./sections/Features";
import Hero from "./sections/Hero";
import Stats from "./sections/Stats";
import Testimonials from "./sections/Testimonials";

function App() {
  return (
    <>
      <Hero />
      <Features />
      <CTA />
      <Stats />
      <Testimonials />
    </>
  );
}

export default App;
